.w-100 {
  width: 100%;
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.text-center {
  align-items: center;
  align-self: center;
  text-align: center;
  margin-bottom: 2%;
}

.bg-white {
  background-color: #fff;
}

.user-form-forgot {
  float: right;
}

.user-form-button {
  background-color: #2e3192;
  box-shadow: none;
  width: 100%;
}

.user-form-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.responsive-card-width {
  width: 65%;
}

@media only screen and (max-width: 992px) {
  .responsive-card-width {
    width: 90%;
  }
}

.card {
  width: 20%;
  flex-wrap: wrap;
  margin: 20px 10px 0px 20px;
  cursor: pointer;
  margin-bottom: 1vh;
}

.container {
  padding: 2vw;
  padding: 1vw;
  overflow: auto;
}

.quiz-start-btn-self {
  margin: 10px 10px;
  background-color: #3e4192;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.quiz-start-btn-self:hover {
  color: white !important;
}

.btn {
  margin: 5px 5px;
  font-weight: 800;
  color: "black";
}

.quittest {
  color: #2e3192;
  border: none;
  margin: 5px 5px;
  border: 1px solid gray;
}

@media only screen and (max-width: 768px) {
  .hideLanguageText {
    display: none;
  }
}

.ant-carousel .slick-dots-bottom li {
  background-color: blue;
}

.ant-carousel .slick-dots-bottom li.slick-active {
  background-color: red;
}

.render-vd-list {
  border: 1px solid #ededed;
  cursor: pointer;
  padding: 5%;
  border-radius: 10px;
  background-color: #eaf0ff;
}

.pdf-list {
  border: 1px solid #ededed;
  cursor: pointer;
  padding: 5%;
  border-radius: 10px;
  background-color: #eaf0ff;
}

.active-tab {
  border: 1px solid #23c550;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
  padding: 5%;
  border-radius: 10px;
  background-color: white;
}

.live-tab {
  border: 1px solid #ff0000;
  cursor: pointer;
  padding: 5%;
  border-radius: 10px;
  background-color: white;
}

.currentVideoPlay {
  font-size: 1.3rem;
  color: #13b541;
}

.liveVideoPlay {
  font-size: 1.3rem;
  color: #ff0000;
}

.noVideoPlay {
  font-size: 1.3rem;
  color: #ffffff;
}

/* .upcomingVideoPlay {
  color: "#9997C2";
  font-size: 1.5rem;
} */

.liveTag {
  background-color: #fff2f0;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 10px;
  margin-left: 5%;
}

.deactiveUpComingClass {
  background-color: #ededed;
  border: 1px solid #ededed;
  cursor: none;
  padding: 5%;
  border-radius: 10px;
  user-select: none;
}

.status {
  display: inline-block;
  background: #ddd;
  border-radius: 3px;
  margin-left: 6px;
  padding: 0.2em 1em;
  font-size: 0.65em;
  font-weight: 700;
}

.scheduled-text .status p {
  background: red;
  color: white;
}

.nav-icon {
  color: #3e4192;
}

.notes-pdf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0 3px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0 3px 0;
  cursor: pointer;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .fonts-on-mobile-12px {
    font-size: 12px;
  }

  .hide-on-mobile {
    display: none;
  }

  .heading {
    display: none;
  }

  .show-on-mobile {
    display: block;
  }

  .user-form {
    width: 80%;
    padding: 5vw;
  }

  .quiz-start-img {
    display: none;
  }

  .profile {
    margin: 1vh auto 1vh auto;

    width: 100% !important;
  }

  .vjs-sidebar-content {
    width: 100% !important;
  }
}

.card-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.card-content2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.content-sidebar {
  height: 100vh;
  width: 0px;
  transition: 0.4s ease-in;
  border-left: 1px solid rgba(var(--bs-light-rgb));
}

.chat-sidebar {
  width: 25%;
}

.content-container {
  min-height: 40vh;
  flex: 1;
}

.vjs-sidebar-content {
  width: 25%;
}

.renderChat {
  width: 100%;
  height: 100%;
}

.renderChat iframe {
  border-width: 0px;
  width: 100%;
  height: 100%;
}

.chat-sidebar {
  width: 25%;
}

.course-player-container {
  display: flex;
  margin-top: 0.2%;
  height: 93vh;
}

.notes-pdf-icon {
  position: relative;
  top: 0.3vh;
}

.video-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  cursor: pointer;
}

.control-bar {
  display: flex;
  height: 2.5em;
  flex-direction: row;
}

.control-bar-container {
  width: 100%;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 20%,
    rgba(255, 255, 255, 0.1)
  );
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.control-bar-left {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 3em;
  color: white;
  justify-content: flex-start;
}

.control-bar-right {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-inline: 0.5em;
  font-size: 1.8em;
}

.timeline-container {
  height: 0.5em;
  margin-inline: 0.3em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.timeline {
  width: 100%;
  height: 0.3em;
  background-color: rgba(150, 150, 150, 0.3);
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: calc(100% - var(--preview-progress, 0) * 100%);
  background-color: rgba(100, 100, 100, 0.5);

  display: block;
}

.timeline::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: calc(100% - var(--progress-position) * 100%);
  background-color: rgb(30, 176, 76);
}

.timeline .thumb-indicator {
  --scale: 0;
  position: absolute;
  height: 200%;
  transform: translate(-50%) scale(var(--scale));
  top: -50%;
  left: calc(var(--progress-position) * 100%);
  background-color: rgb(30, 176, 76);
  border-radius: 50%;
  transform: transform 150ms ease-in-out;
  aspect-ratio: 1/1;
  box-shadow: 0 0 0 7px rgb(30, 176, 76, 0.4);
}

.play-btn-container {
  opacity: 1;
  font-size: 0.6em;
  height: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: white;

  border-radius: 5%;
}

.play-btn-container .center {
  background-color: white;
  background-color: rgb(30, 176, 76);
  padding: 5px;
  border-radius: 10%;
}

.custom-player-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: #383386;
}

.custom-player-wrapper.scrubbing .timeline::before,
.timeline-container:hover .timeline::before {
  display: block;
}

.timeline-container:hover .timeline::after {
  box-shadow: 0 0 0 4px rgb(30, 176, 76, 0.05);
}

.custom-player-wrapper.scrubbing .timeline,
.timeline-container:hover .timeline {
  height: 100%;
}

.custom-player-wrapper.scrubbing .thumb-indicator,
.timeline:hover .thumb-indicator {
  --scale: 1;
}

.settings-container {
  position: absolute;
  bottom: 1.5em;
  padding: 0px;
  cursor: pointer;
}

.settings-container ul {
  border-radius: 0.4em;
  background-color: rgb(20, 20, 20, 0.4);
  list-style: none;
  padding: 0.9em;
  font-size: 0.5em;
  color: white;
  background: linear-gradient(
    0deg,
    rgba(20, 20, 20, 0.4) 30%,
    rgba(20, 20, 20, 0.7)
  );
}

.settings-container li {
  margin-bottom: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
}

.sound-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.6em;
  margin-inline: 0.2em;
}

#range2 {
  -webkit-appearance: none;
  appearance: none;
  width: 3em;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  display: none;
}

.sound-container:hover #range2 {
  display: block;
}

#range2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: rgba(30, 176, 76);
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

#range2::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: rgba(30, 176, 76);
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

#range2::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 5px rgb(30, 176, 76, 0.1);
}

#range2:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 5px rgba(30, 176, 76, 0.2);
}

#range2:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 5px rgba(30, 176, 76, 0.2);
}

#range2::-moz-range-thumb:hover {
  box-shadow: 0 0 0 5px rgba(30, 176, 76, 0.1);
}

#range2:active::-moz-range-thumb {
  box-shadow: 0 0 0 5px rgba(30, 176, 76, 0.2);
}

#range2:focus::-moz-range-thumb {
  box-shadow: 0 0 0 5px rgba(30, 176, 76, 0.2);
}

.live-indicator {
  color: red;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.3), 0 0 5px rgba(255, 0, 0, 0.3),
    0 0 20px rgba(255, 0, 0, 0.3), 0 0 30px rgba(255, 0, 0, 0.3),
    0 0 40px rgba(255, 0, 0, 0.3), 0 0 55px rgba(255, 0, 0, 0.3),
    0 0 70px rgba(255, 0, 0, 0.3);
}

/* .hidden-settings {
  display: none;
} */

.go-to-live {
  padding: 0.2em 0.1em 0.1em 0.2em;
  background-color: rgba(150, 150, 150, 0.4);
  border-radius: 5%;
  font-size: 0.8em;
  cursor: pointer;
}

.time {
  font-size: 1rem;
  margin: 0.5em;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hideScrollbar {
  overflow: auto;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.profile {
  margin: 5vh auto 5vh auto;
  width: 50%;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 140px;
  height: 32px;
  background-color: #f8f8ff;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #f8f8ff;
  -webkit--moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 50%;
  background-color: #123283;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 65px;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #fff;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #9291a5;
}

.switch {
  position: relative;
  display: inline-block;
  margin: 0 5px;
}

.switch > span {
  position: absolute;
  top: 10px;
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 12px;
  /* text-transform: uppercase; */
  /* text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); */
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  color: #fff;
  align-items: center;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #9291a5;
}

.switchCourses {
  position: relative;
  display: inline-block;
}

.switchCourses > span {
  position: absolute;
  top: 0.7em;
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-size: 0.8em;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchCourses > span.on {
  left: 0;
  padding-left: 2px;
  color: #fff;
  align-items: center;
}

.switchCourses > span.off {
  right: 0;
  padding-right: 4px;
  color: #9291a5;
}

.question-bookmarked {
  background-color: #9b59b6;
  color: #fff !important;
  /* font-weight: bold; */
}

.question-attempted {
  background-color: #27ae60;
  color: #fff !important;
}

.question-default {
  background-color: white;
  color: black;
}

.layout {
  width: 80%;
  height: 90vh;
  margin: auto;
  margin-left: 2rem;
  margin-right: 2rem;
}

.currentQuestion {
  background-color: #c0392b;
  color: #fff !important;
}

.notVisited {
  background-color: #fff;
  color: black !important;
}

.visitedQuestion {
  background-color: #dfe4ff;
  color: #2e3192 !important;
}

.activeQues {
  background-color: #2e3192;
  color: white !important;
}

.stringValue {
  background: lightskyblue;
  color: white;
}

.question-content::-webkit-scrollbar {
  display: none;
}

.quiz-questions img {
  width: auto;
  height: auto;
  max-width: 28vw;
  max-height: 23vh;
}

.quePaper img {
  width: auto;
  height: auto;
  max-width: 20vw;
  max-height: 15vh;
}

.review-questions img {
  width: auto;
  max-width: 25vw;
  height: auto;
  max-height: 20vh;
}

.review-questions p {
  font-size: 1.2em;
  margin: 0%;
}

.quiz-options-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  height: auto;
  background-color: #f5f6ff;
  padding-left: 1%;
  font-size: 1.1em;
}

.blueColor {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #2e3192;
  color: white;
  font-weight: bold;
  height: auto;
  cursor: pointer;
  font-size: 1.1em;
  padding-left: 1%;
}

.quiz-options img {
  max-width: 7vw;
  width: auto;
}

.quiz-options-wrapper:hover {
  background-color: aliceblue;
}

.custom-btn {
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
}

.quiz-start-btn-self_1 {
  background-color: #3e4192;
  color: white !important;
  font-weight: bold;
}

.instructions {
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  margin-left: 0.8rem;
}

.video-content-container {
  flex: 1;
  overflow-y: scroll;
}

/* Hide the scrollbar for Chrome, Safari and Opera */
.video-content-container::-webkit-scrollbar {
  width: 0.5em;
  height: 1em;
}

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.video-content-container::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;   */
  border-radius: 1em;
}

.video-content-container::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  /* background:#5D76A9;  */
  border-radius: 10px;
  height: 10px;
}

.bg-image {
  background-image: url("/public/Group\ 31.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* padding-bottom: 1rem; */
  position: fixed;
}

:where(.css-dev-only-do-not-override-d592we).ant-layout
  .ant-layout-sider-zero-width-trigger-right {
  inset-inline-start: -65px !important;
  border: 2px solid red !important;

  background-color: green !important;
}

.question-content::-webkit-scrollbar {
  display: none;
}

.question-content {
  background-color: #ffffff;
  height: 90vh;
  width: 100%;
  overflow: hidden;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.HeaderLeaderboard {
  width: 100%;
  /* height: 80px; */
  background: whitesmoke;
}

.quiz-result-list {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 1% 1% 1% 2%;
}

.quiz-result-list li {
  list-style: none;
}

.quiz-result-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.question-incorrect {
  background-color: red;
  color: white;
}

.pagination {
  background-color: #3e4192;
  color: white;
}

:where(.css-dev-only-do-not-override-dt430q).ant-layout
  .ant-layout-sider-zero-width-trigger {
  top: 50%;
}

.answer-writing-modal {
  /* height:80vh; */
  overflow: auto;
}

.answer-writing-modal::-webkit-scrollbar {
  display: none;
}

.inProgress {
  color: #faad14;
  background: #fffbe6;
  border: 1px solid #faad14;
  text-align: center;
}

.paid {
  background-color: #f6ffed;
  border: 1px solid #52c41a;
  color: #52c41a;
  text-align: center;
}

.failed {
  color: #ff4d4f;
  background: #fff2f0;
  border: 1px solid #ff4d4f;
  text-align: center;
}

.attempt {
  color: #56257d;
  border: 1px solid #56257d;
  background-color: #eae8ff;
}

input[type="file"]::file-selector-button {
  border-radius: 0.5em;
  background-color: #eaf0ff;
  color: #2e3192;
  font-weight: 700;
  border: none;
  font-size: 1em;
  width: 8em;
  padding: 0.5em;
  margin-right: 1em;
}

#attachment {
  width: 17em;
  font-weight: 700;
  font-size: 0.9em;
}

.ant-menu-item-selected {
  border-left: 4px solid white;
  border-radius: 0px !important;
}

.videoPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(180deg, #414141 0%, rgba(65, 65, 65, 0) 100%);
  z-index: 100%;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.imageContainer {
  position: relative;
}

.videoPlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.activeNoOfVideos {
  border: 0.15em solid #a4a1d8;
  background: #2e3192;
  color: white;
  border-radius: 0.5em;
}

.activeClass {
  background-color: #2e3192;
}

.quiz-index {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
  height: 72vh;
  padding-bottom: 3.5rem;
  border-radius: 1rem !important;
}

.quiz-index::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.quiz-questions > p {
  margin-left: 2em !important;
  margin-right: 2em !important;
}

:where(.css-dev-only-do-not-override-d592we).ant-layout
  .ant-layout-sider-light
  .ant-layout-sider-zero-width-trigger {
  color: #2e3192 !important;
}

:where(.css-dev-only-do-not-override-d592we).ant-layout
  .ant-layout-sider-light
  .ant-layout-sider-zero-width-trigger {
  margin-top: -2.3rem !important;
  font-size: 2rem !important;
}

@media screen and (max-width: 768px) {
  .quittest {
    color: #2e3192;
    border: none;
    margin: 5px 5px;
    border: 1px solid #2e3192;
    font-size: 0.6rem;
    margin-bottom: 0.5rem;
  }

  .bg-image {
    background-image: url("/public/Group\ 31.png");
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 1rem;
  }
}

.quizSVG {
  background-image: url(/src/component/icons/Quiz.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  user-select: none;
}

.review-next-btn {
  background-color: #2e3192;
  color: white;
  margin-top: 10px;
  width: 10%;
  border-radius: 0px;
  font-weight: bold;
  margin-right: 5px;
}

.review-next-btn:hover {
  color: white !important;
}

.Mysubmission {
  position: relative;
  left: 7%;
}

.card-active {
  border: 2px solid transparent;
}

.recommended-card {
  margin-bottom: 2%;
  border: 1px solid #dadada;
  box-shadow: 0px 1px 1px #dadada;
}

.card-active:hover {
  border: 2px solid #a79fff;
}

.course-logo {
  width: 1.3rem;
  padding-right: 5px;
}

.tabs_1 {
  color: #2e3192;
  font-family: Urbanist;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.live {
  color: #670000;
  font-family: Urbanist;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9256rem;
  background: #ffecec;
  border-radius: 30px;
  border: 1px solid #c00;
  padding-left: 0.4rem;
}

.recoreded {
  color: #005518;
  font-family: Urbanist;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 150% */
  border-radius: 30px;
  border: 0.5px solid #23c550;
  margin-left: 0.2rem;
  padding-left: 0.4rem;
}

.hindi {
  display: inline-flex;
  padding: 2px 15px 3px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 0.5px solid #c4c4c4;
  background: #f7f7f7;
  margin: 0.2rem;
  font-size: 0.75rem;
}

.modal-modal {
  position: relative;
  top: 10% !important;
  left: 7.2%;
}

.ant-layout-sider-zero-width-trigger,
.ant-layout-sider-zero-width-trigger-right {
  margin-top: 50% !important;
}

@media screen and (max-width: 425px) {
  .img-tag {
    width: 80px !important;
    height: 70px !important;
  }
}

@media screen and (max-width: 576px) {
  .title-header {
    display: none;
  }

  .select-box {
    margin-top: 0.8rem;
  }
}

.upcomingClass {
  background-color: white;
  color: #727272;
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  text-align: center;
  width: fit-content;
  padding: 0px 0.6rem;
}

.deactiveUpComingClass {
  background-color: #ededed;
  border: 1px solid #ededed;
  cursor: not-allowed;
  padding: 5%;
  border-radius: 10px;
  user-select: none;
}

/* for shimmer full width */

.fullWidthImage {
  width: 100% !important;
  max-width: none !important;
}

.bannerShimmer {
  width: 100% !important;
  height: 20em !important;
  max-width: none !important;
}

.course-quiz {
  background-color: white;
}

.course-quiz a {
  color: black;
}

.orderDrawer {
  width: 100%;
  height: 90vh;
  box-shadow: 0px 4px 4px 0px #0000001f;
  margin-top: 2%;
}

.orderDrawerList {
  background-color: #f6f6f6;
  border: none;
  border-radius: 10px;
  width: 90%;
  margin: auto;
}

.orderDrawerListItem {
  width: 53%;
  font-weight: bold;
}

.profileItem {
  padding: 4%;
  border-radius: 10px;
}

.profileItem h3 {
  color: black;
}

.profileItem:hover {
  background-color: #e7ebfc;
  cursor: pointer;
}

.divider {
  border: 1px solid #efefef;
  background: #efefef;
}

@media screen and (max-width: 768px) {
  .dailyWatchRate {
    margin-bottom: 4%;
  }
}

@media screen and (max-width: 1024px) {
  .dailyWatchRate {
    margin-bottom: 4%;
  }
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
  font-weight: 600;
}

.badge .ant-badge-count {
  border: none;
}

.custom-drawer .ant-drawer-close {
  color: white;
}

@media screen and (max-width: 576px) {
  .order-dates {
    width: 75%;
  }
}

@media screen and (max-width: 576px) {
  .academic-card {
    width: 60%;
    bottom: 0.01rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .academic-card {
    width: 40%;
    bottom: 0.01rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .academic-card {
    width: 80%;
    bottom: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .academic-card {
    width: 80%;
    bottom: 3rem;
  }
}

.selectExam {
  background-color: #efeff4;
  border-radius: 1em;
  margin-bottom: 3%;
  cursor: pointer;
  border: 0.15em solid transparent;
}

.selectExam:hover {
  border: 0.15em solid #2e308a;
}

@media screen and (max-width: 1024px) {
  .courseHeader {
    margin-top: 1em;
  }
}

#color123 {
  background-color: #f0eeff !important;
  color: #2e3192;
  font-weight: 500;
  cursor: pointer;
}

.courseVideoScrollbar {
  overflow-y: scroll;
}

.courseVideoScrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.courseVideoScrollbar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #D9D9D9;  */
  border-radius: 1em;
}

.courseVideoScrollbar::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 10px;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.blinkPhone {
  position: absolute;
  animation: myfirst 20s linear 0.2s infinite alternate;
  z-index: 1;
  font-weight: 700;
  font-size: 1em;
  color: red;
}

@keyframes myfirst {
  0% {
    left: 0%;
    top: 1%;
    opacity: 1;
  }

  25% {
    left: 90%;
    top: 50%;
    opacity: 0;
  }

  50% {
    left: 50%;
    top: 90%;
    opacity: 1;
  }

  75% {
    left: 0%;
    top: 60%;
    opacity: 0;
  }

  100% {
    left: 50%;
    top: 90%;
    opacity: 1;
  }
}

#color987 div .ant-select-selector {
  background: #f2f1ff !important;
  border: 1px solid #2e3192;
  color: #2e3192 !important;
  border-radius: 0.6em;
  font-weight: 600 !important;
  width: 100%;
}

#color987 div .ant-select-selector .ant-select-selection-item {
  font-weight: 500 !important;
}

@media screen and (max-width: 768px) {
  .layout {
    width: 80%;
    height: 90vh;
    margin: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
